<template>
  <div v-if="img" class="content">
    <div class="box">
      <div class="top">
        <img class="img1" :src="img" alt />
        <div class="top-phone" @click="tel">
          <img class="phone" src="@/assets/image/phone.png" alt />
        </div>
        <div class="top-ck" @click="govneue">
          <img src="@/assets/image/地图.png" alt />
          <span>查看全景</span>
        </div>
      </div>
      <div class="bottom">
        <div class="left">
          <div class="title">{{areaTitle}}</div>
          <div class="left-bom">
            <img src="@/assets/image/时间.png" alt />
            <div class="time">开放时间: {{openStartTime}}-{{openEndTime}}</div>
          </div>
        </div>
        <div class="but" @click="gofield">查看详情</div>
      </div>
    </div>
    <van-cell-group inset>
      <van-field v-model="value" autosize readonly type="textarea" rows="1" label="参观区域:" />
      <van-field v-model="value1" autosize readonly type="textarea" rows="1" label="其他说明:" />
    </van-cell-group>

    <van-cell-group inset>
      <van-field label="预约时段" required readonly>
        <template #input>
          <div class="tet">
            <div @click="seldata">
              <span>{{rq}}</span>
              <img class="tet-img" src="@/assets/image/4.png" alt />
            </div>
            <div @click="seltime">
              <span>{{sj}}</span>
              <img class="tet-img" src="@/assets/image/4.png" alt />
            </div>
          </div>
        </template>
      </van-field>
      <van-field v-model="value2" label="参观领导" placeholder="请输入参观领导" />
      <van-field v-model="value3" required label="参观人数" placeholder="请输入参观人数" />
    </van-cell-group>

    <van-cell-group inset>
      <van-field v-model="name" required label="联系人" placeholder="请输入联系人" />
      <van-field v-model="phone" required label="联系电话" placeholder="请输入联系电话" />
      <van-field v-model="company" required label="公司/部门" placeholder="请输入公司/部门" />
      <van-field v-model="emil" label="电子邮箱" placeholder="请输入电子邮箱" />
    </van-cell-group>

    <div class="message">
      <span style="font-size:16px">备注说明/留言</span>
      <van-field
        v-model="message"
        rows="2"
        autosize
        type="textarea"
        maxlength="100"
        placeholder="请输入备注说明或者留言"
        show-word-limit
      />
    </div>
    <div class="btn" @click="submit">确认以上信息，并提交</div>
    <van-action-sheet v-model="show3" title="选择日期">
      <div>
        <van-datetime-picker
          v-model="currentDate"
          type="date"
          :min-date="minDate"
          :max-date="maxDate"
          visible-item-count="5"
        >
          <template #default>
            <div></div>
          </template>
        </van-datetime-picker>
        <div class="date-bottom">
          <div class="no" @click="show3 = false">取 消</div>
          <div @click="submittime">确 定</div>
        </div>
      </div>
    </van-action-sheet>
    <van-action-sheet v-model="show4" title="预约时段">
      <div class="tent">
        <div class="time-top">(一小时起订，灰色为已被预订)</div>
        <div class="rqsj">{{rq+' '+rqsj}}</div>
        <div class="tent-box">
          <div
            :id="item.status?'hz':''"
            class="box-time"
            v-for="(item,index) in datetime"
            :key="index"
            :class="item.sell?'sell':''"
            @click="xztime(index)"
          >{{item.time}}</div>
        </div>
        <div class="date-bottom">
          <div class="timebut no" @click="show4=false">取 消</div>
          <div class="timebut" @click="subhmm">确 定({{time}}小时)</div>
        </div>
      </div>
    </van-action-sheet>
  </div>
  <van-loading v-else size="24px" style="text-align:center;margin-top:70px">加载中...</van-loading>
</template>

<script>
import moment from 'moment'
import {
  getAreaVisitDetail,
  subscribeVisit,
  getSubscribeTime
} from '@/api/user'
import { Toast } from 'vant'
export default {
  data() {
    return {
      sj: '请选择时间段',
      rq: '请选择日期',
      openEndTime: '',
      openStartTime: '',
      img: '',
      areaTitle: '',
      currentDate: '',
      minDate: '',
      maxDate: '',
      datetime: [],
      time: 0,
      show3: false,
      show4: false,
      value: '',
      value1: '',
      value2: '',
      value3: '',
      name: '',
      phone: '',
      company: '',
      emil: '',
      message: '',
      id: '',
      startTime: '',
      endTime: '',
      ww: false,
      rqsj: '',
      areaPhoen: '',
      open: true
    }
  },
  watch: {
    ww: {
      handler() {
        let a = 0
        let end = ''
        this.datetime.forEach((res, index) => {
          if (res.sell) {
            a = a + 1
            end = index
          }
        })
        const sta = this.datetime.findIndex(res => {
          return res.sell
        })
        if (a >= 2) {
          const arr = this.datetime.slice(sta, end + 1)
          let flag = false
          arr.forEach(res => {
            if (res.status === 1) {
              flag = true
            }
          })
          if (flag) {
            Toast('请选择连续时间段')
            this.datetime.forEach(res => {
              res.sell = false
            })
            this.time = 0
            this.rqsj = ''
          } else {
            let a = -0.5
            this.datetime.forEach((res, index) => {
              if (index >= sta && index <= end) {
                res.sell = true
              }
            })
            this.datetime.forEach(res => {
              if (res.sell) {
                a = a + 0.5
              }
            })
            this.rqsj = this.datetime[sta].time + '-' + this.datetime[end].time
            this.time = a
          }
        } else {
          this.rqsj = ''
          this.time = 0
        }
      },
      deep: true
    }
  },
  created() {
    this.id = this.$route.query.id
    this.currentDate = new Date()
    this.minDate = new Date()
    this.maxDate = new Date(2032, 10, 1)
    console.log(this.$route.query.id)
    this.getAreaVisitDetail()
  },
  methods: {
    govneue() {
      localStorage.setItem('flag', 0)
      this.$router.push('/venue')
    },
    gofield() {
      localStorage.setItem('title', this.areaTitle)
      this.$router.push(`/textfield?id=${this.id}&flag=2`)
    },
    tel() {
      window.location.href = 'tel:' + this.areaPhoen
    },
    subhmm() {
      if (this.time < 1) {
        Toast.fail('一小时起订')
      } else if (this.time > 8) {
        Toast.fail('单次预约不得超过8小时')
      } else {
        const startTime = this.datetime.find(res => {
          return res.sell === true
        })
        const arr = []
        this.datetime.forEach(res => {
          if (res.sell) {
            arr.push(res)
          }
        })
        this.endTime = arr[arr.length - 1].time
        this.startTime = startTime.time
        console.log(this.startTime, this.endTime)
        this.show4 = false
        this.sj = this.startTime + '-' + this.endTime
      }
    },
    async throttle(obj) {
      if (this.open === true) {
        this.open = false
        const { data } = await subscribeVisit(obj)
        if (data.code === 200) {
          this.open = true
          Toast.success('预约成功')
          this.$router.push('/appointment')
        } else {
          this.open = true
          Toast.fail(data.msg)
        }
      } else {
        Toast.fail('请勿重复提交表单')
      }
    },
    async submittime() {
      this.rq = moment(this.currentDate).format('YYYY-MM-DD')
      this.show3 = false
      const { data } = await getSubscribeTime({
        areaId: this.id,
        date: this.rq,
        orderType: 1
      })
      if (data.code === 400) {
        Toast('当日不开放')
        this.rq = '请选择日期'
      } else {
        let date = new Date()
        const aaa = moment(date).format('YYYY-MM-DD')
        date = String(date)
          .substring(16)
          .substring(0, 2)
        data.data.forEach(res => {
          if (res.status === 0) {
            res.sell = false
          }
          if (
            Number(res.time.substring(0, 2)) <= Number(date) &&
            aaa === this.rq
          ) {
            res.status = 1
          }
        })
        this.sj = '请选择时间段'
        this.datetime = data.data
      }
    },
    async submit() {
      const phonereg = 11 && /^((13|14|15|16|17|18|19)[0-9]{1}\d{8})$/
      if (this.rq === '请选择日期' || this.sj === '请选择时间段') {
        Toast('请选择预约时段')
      } else if (!this.value3) {
        Toast('请填写参观人数')
      } else if (!this.name) {
        Toast('请填写联系人')
      } else if (!this.phone) {
        Toast('请填写联系电话')
      } else if (!this.company) {
        Toast('请填写公司/部门')
      } else if (!phonereg.test(this.phone)) {
        Toast('手机号格式不正确')
      } else {
        const obj = {
          areaId: this.id,
          leader: this.value2,
          attendance: this.value3,
          contact: this.name,
          phone: this.phone,
          department: this.company,
          email: this.emil,
          remarks: this.message,
          startTime: this.rq + ' ' + this.startTime,
          endTime: this.rq + ' ' + this.endTime
        }
        const obj1 = {}
        for (const k in obj) {
          if (obj[k]) {
            obj1[k] = obj[k]
          }
        }
        this.throttle(obj1)
        // const { data } = await subscribeVisit(obj1)
        // if (data.code === 200) {
        //   Toast.success('预约成功')
        //   setTimeout(() => {
        //     this.$router.push('/appointment')
        //   }, 2000)
        // } else {
        //   Toast.fail(data.msg)
        // }
      }
    },
    async getAreaVisitDetail() {
      const { data } = await getAreaVisitDetail({
        id: this.id
      })
      console.log(data.data)
      this.img = data.data.areaImg
      this.areaTitle = data.data.areaTitle
      this.openStartTime = data.data.openStartTime
      this.openEndTime = data.data.openEndTime
      this.value = data.data.visitArea
      this.value1 = data.data.other
      this.areaPhoen = data.data.areaPhoen
    },
    xztime(row) {
      this.datetime.forEach((res, index) => {
        if (row === index && res.status !== 1) {
          this.ww = !this.ww
          if (res.sell) {
            res.sell = false
          } else {
            res.sell = true
          }
        }
      })
    },
    seldata() {
      if (this.rq !== '请选择日期') {
        this.currentDate = new Date(this.rq)
      }
      this.show3 = true
    },
    seltime() {
      if (this.rq === '请选择日期') {
        Toast.fail('请先选择日期')
      } else {
        this.show4 = true
      }
    }
  }
}
</script>

<style lang="less" scoped>
/deep/ .van-cell {
  line-height: 26px;
}
.content {
  .rqsj {
    margin: 5px 0;
    font-size: 14px;
  }
  /deep/ .van-action-sheet__header {
    line-height: 36px;
  }
  /deep/ .van-field__control {
    overflow: hidden;
  }
  padding-bottom: 20px;
  /deep/ .van-picker__toolbar {
    height: 0px;
  }
  #hz {
    background-color: #aaa;
    color: #fff;
  }
  .date-top {
    margin: 0 auto;
  }
  .sell {
    color: #fff;
    background-color: #f8892f !important;
  }
  .time-top {
    color: #999;
    font-size: 12px;
    text-align: center;
  }
  .date-bottom {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .no {
      background-color: #e5e5e5;
      color: #333333;
    }
    div {
      width: 35vw;
      height: 42px;
      line-height: 42px;
      text-align: center;
      color: #fff;
      background-color: #f8892f;
      border-radius: 19px;
      font-size: 14px;
      margin: 25px 10px 15px;
    }
    .timebut {
      margin: 10px 10px 0 !important;
    }
  }
  .tent {
    padding: 0 16px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .tent-box {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      width: 90vw;
      div {
        font-size: 14px;
        margin-right: 3vw;
        margin-bottom: 10px;
        width: 27vw;
        height: 43px;
        background-color: #efefef;
        border-radius: 8px;
        line-height: 43px;
        text-align: center;
      }
      .sel {
        background-color: #fff3eb;
        color: #f8892f;
        border: 1px solid #f8892f;
        width: calc(27vw - 2px);
        height: 41px;
        line-height: 41px;
      }
      .box-time {
        width: 21vw;
        margin-right: 1.5vw;
      }
    }
    .tent-but {
      width: 90vw;
      height: 45px;
      line-height: 45px;
      text-align: center;
      color: #fff;
      border-radius: 10px;
      font-size: 14px;
      background-color: #f8892f;
      margin-top: 10px;
    }
  }
  .tet {
    width: 100%;
    color: #666;
    display: flex;
    align-items: center;
    justify-content: space-between;
    line-height: 28px;
    img {
      width: 10px;
    }
    .tet-img {
      margin-left: 20px;
    }
  }
  .btn {
    width: 94%;
    margin-left: 3%;
    height: 50px;
    color: #fff;
    background-color: #f8892f;
    text-align: center;
    line-height: 50px;
    font-size: 14px;
    border-radius: 25px;
    margin-top: 15px;
  }
  .message {
    display: flex;
    flex-direction: column;
    padding: 10px;
    box-sizing: border-box;
    overflow: hidden;
    width: 94%;
    margin-left: 3%;
    background-color: #fff;
    height: 120px;
    border-radius: 15px;
    span {
      font-size: 14px;
      color: #646566;
      margin-bottom: 5px;
    }
    /deep/ .van-cell {
      padding: 0;
    }
    /deep/ .van-cell__value {
      border: 0.5px #e7e7e7 solid;
      border-radius: 10px;
      padding: 5px;
      box-sizing: border-box;
    }
    /deep/ .van-field__word-limit {
      color: #e7e7e7;
    }
  }
  height: 100%;
  width: 100%;
  /deep/ .van-cell-group--inset {
    width: 94%;
    margin: 0 0 10px 3%;
    .van-field__label {
      width: 5.2em;
      margin-right: 0px;
    }
  }
  .box {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    border-radius: 12px;
    background-color: #fff;
    width: 94%;
    margin-left: 3%;
    height: 247px;
    overflow: hidden;
    .top {
      position: relative;
      .top-ck {
        position: absolute;
        bottom: 10%;
        left: 3%;
        display: flex;
        background: rgba(0, 0, 0, 0.4);
        color: #fff;
        font-size: 14px;
        line-height: 14px;
        align-items: center;
        justify-content: center;
        border-radius: 10px;
        height: 23px;
        width: 90px;
        img {
          width: 12px;
          height: 12px;
          margin-right: 5px;
        }
      }
      .top-phone {
        border: none;
        outline: none;
        height: 38px;
        width: 38px;
        background: rgba(0, 0, 0, 0.4);
        border-radius: 50% 50%;
        position: absolute;
        bottom: 6%;
        right: 3%;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          width: 18px;
          height: 18px;
        }
      }
    }
    .img1 {
      width: 100%;
      height: 173px;
    }
    .bottom {
      height: 74px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 5px 15px 10px 15px;
      box-sizing: border-box;
      .left {
        display: flex;
        flex-direction: column;
        align-self: left;
        .left-bom {
          display: flex;
          align-items: center;
        }
        img {
          width: 13px;
          height: 13px;
          margin-right: 3px;
        }
        .title {
          font-size: 18px;
          color: #333333;
          font-weight: bold;
          margin-bottom: 12px;
        }
        .time {
          font-size: 14px;
          color: #969696;
        }
      }
    }
    .but {
      font-size: 14px;
      border-radius: 16px;
      width: 100px;
      height: 34px;
      background-color: #f8892f;
      color: #fff;
      line-height: 34px;
      text-align: center;
      border: none;
      outline: none;
    }
  }
  /deep/ .van-cell {
    font-size: 15px;
  }
}
</style>
